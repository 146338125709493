<template>
  <v-container id="empty" fluid tag="section"></v-container>
</template>

<script>
export default {
  name: 'Empty',
  data() {
    return {}
  },
}
</script>
